import { Box, Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { CmpButtonOld, CmpInputNotion, CmpTypography } from '../../component/ToolBox/ToolBox';
import '../Notion/Notion.css';
import SearchModal from './SearchModal';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { fAlertToast } from '../../Utility/Utilitys';
import { notionFlyerApproveAPI } from './action';
import { useSelector } from 'react-redux';
import ConfirmMessage from './ConfirmMessage';
import { httpRequest } from '../../Server/Axios';

const columns = [
  { field: 'MainPageName', headerName: 'Page', width: 210 },
  { field: 'PageName', headerName: 'Sub Page', width: 270 },
  { field: 'MainGroupName', headerName: 'Main Group', width: 200 },
  { field: 'SubGroupName', headerName: 'Sub Group', width: 200 },
  { field: 'CategoryName', headerName: 'Category Name', width: 200 },
  { field: 'NoOfItems', headerName: 'NoofItems', width: 100 },
  { field: 'SavedItems', headerName: 'SavedItems', width: 100 }
];

const Notion = () => {
  const vMediaQuery = useMediaQuery('(max-width:550px)');
  const xMediaQuery = useMediaQuery('(max-width:900px)');
  const aMediaQuery = useMediaQuery('(max-width:1300px)');
  const uMediaQuery = useMediaQuery('(max-width:1450px)');
  const userInfo = useSelector((state) => state.SliceDB.value.login);
  const [open, setOpen] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const [flyerLocData, setFlyerLocData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const location = useLocation();
  const [isPopModalDataChanged, setIsPopModalDataChanged] = useState(false);

  const handleRowClick = (item) => {
    setSelectedItems(item.row);
    setOpen(true);

    setTimeout(() => {
      document.getElementById('ItemName').focus();
    }, 500);
  };

  // Extract the ID from the query string
  const queryParams = new URLSearchParams(location.search);
  const ID = queryParams.get('id'); // Corrected to fetch 'ID' from the query string

  const fetchFlyerDetails = async () => {
    if (ID) {
      try {
        const response = await httpRequest(
          {
            method: 'POST',
            url: '/bakeryPreBooking/getFlyerDetails', // Only relative path, not full URL
            data: { ID }
          },
          true
        ); // Passing 'true' to use REACT_APP_BASE_URL_NOTION

        setInfoData(response.data.FlyerHeader);
        setFlyerLocData(response.data.FlyerLocations);

        const GridID = response.data.FlyerPages?.map((obj, i) => ({
          ...obj,
          id: i + 1
        }));
        setGridData(GridID);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    fetchFlyerDetails();
  }, [location.search]);

  const handleClose = () => {
    if (isPopModalDataChanged) {
      fetchFlyerDetails();
    }
    setIsPopModalDataChanged(false);
    setOpen(false);
  };

  const flyerLocCV = flyerLocData?.map((location) => location.loc).join(','); // Convert array to comma-separated string

  const fPost = async () => {
    const obj = {
      v_UId: '0',
      v_Name: infoData?.Name1,
      v_StartDt: moment(infoData?.FromDate).format('MM/DD/YYYY'),
      v_EndDt: moment(infoData?.ToDate).format('MM/DD/YYYY'),
      v_Locations: flyerLocCV,
      v_UserId: userInfo?.userId,
      v_NOTION_ID: infoData?.ID
    };

    const result = await notionFlyerApproveAPI(obj);
    if (result) {
      fAlertToast('SUCCESS', 'Data Posted successfully');
    } else {
      fAlertToast('ERROR', `Data does n't Posted successfully`);
    }
    fetchFlyerDetails();
  };

  const confrimApprove = async () => {
    if (infoData?.Status === 'ENTER') {
      if (gridData.length === 0) {
        fAlertToast('ERROR', 'No items selected for approval.');
        return;
      }
    }
    confirmAlert({
      title: 'Approve',
      message: 'Are you sure?',
      buttons: [
        {
          className: 'alertBtn',
          label: 'Yes',
          onClick: () => {
            console.log('Selected Rows:', gridData);
            fPost();
          }
        },
        {
          className: 'alertBtn',
          label: 'No',
          onClick: () => console.log('Approval Canceled')
        }
      ]
    });
  };
  const fApprove = async () => {
    if (infoData?.Status === 'ENTER') {
      if (gridData.length === 0) {
        fAlertToast('ERROR', 'No items selected for approval.');
        return;
      }
      confirmAlert({
        title: 'Confirmation',
        message: <ConfirmMessage selectedRows={gridData} />,
        buttons: [
          {
            className: 'alertBtn',
            label: 'Yes',
            onClick: () => {
              console.log('Selected Rows:', gridData);
              setTimeout(() => {
                confrimApprove();
              }, 200);
            }
          },
          {
            className: 'alertBtn',
            label: 'No',
            onClick: () => console.log('Approval Canceled')
          }
        ]
      });
    }
  };

  return (
    <div className="notion-container">
      <div>
        <ToastContainer />
      </div>
      <Box sx={{ flexGrow: 1, pt: 2 }}>
        <Grid container columnSpacing={2} paddingTop={vMediaQuery ? 0 : 2}>
          {/* Info Section */}
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="h6" gutterBottom>
              Flyer Information
            </Typography>
            <Box className="notion-info">
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'Flyer ID'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xReadOnly={true}
                    xType={'text'} // text/number/password
                    xValue={infoData?.ID}
                    xName={'ID'}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'Flyer Name'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={infoData?.Name1}
                    xType={'text'} // text/number/password
                    xName={'Name1'}
                    xReadOnly={true}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'From Date'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={moment(infoData?.FromDate).format('DD/MM/YYYY')}
                    xType={'text'} // text/number/password
                    xName={'FromDate'}
                    xReadOnly={true}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'To Date'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={moment(infoData?.ToDate).format('DD/MM/YYYY')}
                    xType={'text'} // text/number/password
                    xName={'ToDate'}
                    xReadOnly={true}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3}>
                  <CmpTypography xcomponent={'span'} xText={'Status'} />
                </Grid>
                <Grid item xs={1} sm={1}>
                  <CmpTypography xcomponent={'span'} xText={':'} />
                </Grid>
                <Grid item xs={8} sm={7}>
                  <CmpInputNotion
                    xTextAlign="left"
                    xValue={infoData?.Status}
                    xType={'text'} // text/number/password
                    xName={'Status'}
                    xReadOnly={true}
                    xErrorMessage={''}
                    xOnKeyUp={''}
                    xTabIndex={'4'}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Stores Card */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Locations
            </Typography>
            <div>
              <Card className="notion-card">
                <CardContent>
                  <Box sx={{ height: '12vh', overflow: 'auto', padding: '0 !important' }}>
                    {flyerLocData?.map((loc, index) => (
                      <Typography variant="body1" sx={{ paddingY: '2px' }} key={index}>
                        {loc.locName}
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
              </Card>
              <div style={{ marginTop: vMediaQuery ? '10px' : '20px', textAlign: 'center' }}>
                <CmpButtonOld
                  xLabel={'Approve'}
                  xvariant={'contained'}
                  xonClick={fApprove}
                  xDisable={infoData?.Status === 'POST'}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          {/* Pages Data Grid */}
          <Grid item xs={12}>
            <Box sx={{ height: 300, width: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Pages
              </Typography>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root': {
                    height: '55vh !important'
                  }
                }}
                className="notion-grid"
                rows={gridData}
                columns={columns}
                onRowClick={(item) => {
                  handleRowClick(item);
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <SearchModal
        open={open}
        handleClose={handleClose}
        items={selectedItems}
        infoData={infoData}
        flyerLocData={flyerLocData}
        vMediaQuery={vMediaQuery}
        xMediaQuery={xMediaQuery}
        aMediaQuery={aMediaQuery}
        uMediaQuery={uMediaQuery}
        setIsPopModalDataChanged={setIsPopModalDataChanged}
      />
    </div>
  );
};

export default Notion;
